import { REVIEWS_WIDGET_NAMES } from "@/constants/widget-names";
import { REVIEW_TYPE } from "@apps/reviews/constants";
import ReviewEntity from "@apps/reviews/entities/ReviewEntity";
import MinilogLogger from "@lib/Logger";
import { BehaviorSubject } from "rxjs";

type OpenReviewFormModal = {
    productId?: number;
    widget: REVIEWS_WIDGET_NAMES;
    reviewType: REVIEW_TYPE;
    reviewRequestToken?: string | undefined;
    onLeaveReview?: (review: ReviewEntity) => void;
};

export default class ReviewFormModalManager {
    public showModal$ = new BehaviorSubject<boolean>(false);

    private productId$ = new BehaviorSubject<number | undefined>(undefined);
    private widget$ = new BehaviorSubject<REVIEWS_WIDGET_NAMES | undefined>(
        undefined
    );
    private reviewType$ = new BehaviorSubject<REVIEW_TYPE>(
        REVIEW_TYPE.SITE_REVIEW
    );
    public reviewRequestToken: string | undefined = undefined;
    public onLeaveReview: ((review: ReviewEntity) => void) | undefined;

    constructor(private readonly logger: MinilogLogger) {
        logger.info("ReviewsFormModalManager initialized.");
    }

    openReviewFormModal({
        productId,
        widget,
        reviewType,
        reviewRequestToken,
        onLeaveReview,
    }: OpenReviewFormModal) {
        this.logger.info("Open review modal with params: ", {
            productId,
            widget,
        });

        this.productId$.next(productId);
        this.showModal$.next(true);
        this.widget$.next(widget);
        this.reviewType$.next(reviewType);
        this.reviewRequestToken = reviewRequestToken;
        this.onLeaveReview = onLeaveReview;
    }

    closeReviewModal() {
        this.showModal$.next(false);
    }

    resetReviewModal() {
        this.productId$.next(undefined);
        this.widget$.next(undefined);
    }

    selectShowModal() {
        return this.showModal$.pipe();
    }

    selectProductId() {
        return this.productId$.pipe();
    }

    selectWidget() {
        return this.widget$.pipe();
    }

    selectReviewType() {
        return this.reviewType$.pipe();
    }

    getReviewRequestToken() {
        return this.reviewRequestToken;
    }
}
