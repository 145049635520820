//Do not delete or edit enum value (REWARDS_POP_UP_WIDGET = "RewardsPopUpWidget"),
//                                                           ^^^^^^^^^^^^^^^^^^

export enum REWARDS_WIDGET_NAMES {
    REWARDS_POP_UP_WIDGET = "RewardsPopUpWidget",
    REWARDS_POP_UP_ROOT_WIDGET = "RewardsPopUpRootWidget",
    REWARDS_VERIFY_ACCOUNT_MODAL_WIDGET = "RewardsVerifyAccountModalWidget",
    REWARDS_JOIN_NOW_MODAL_WIDGET = "RewardsJoinNowModalWidget",
    REWARDS_WELCOME_BANNER_PAGE_WIDGET = "RewardsWelcomeBannerPageWidget",
    REWARDS_HOW_IT_WORKS_PAGE_WIDGET = "RewardsHowItWorksPageWidget",
    REWARDS_WAYS_TO_EARN_PAGE_WIDGET = "RewardsWaysToEarnPageWidget",
    REWARDS_WAYS_TO_EARN_PAGE_WIDGET_MODAL = "RewardsWaysToEarnPageWidgetModal",
    REWARDS_WAYS_TO_SPEND_PAGE_WIDGET = "RewardsWaysToSpendPageWidget",
    REWARDS_WAYS_TO_SPEND_PAGE_WIDGET_MODAL = "RewardsWaysToSpendPageWidgetModal",
    REWARDS_CUSTOMER_REWARDS_MODAL = "RewardsCustomerRewardsModal",
    REWARDS_VIP_TIERS_PAGE_WIDGET = "RewardsVipTiersPageWidget",
    REWARDS_REFERRAL_PROGRAM_PAGE_WIDGET = "RewardsReferralProgramPageWidget",
    REWARDS_EARN_POINTS_PRODUCT_PAGE_WIDGET = "RewardsEarnPointsProductPageWidget",
    REWARDS_EMPTY_STATE_PAGE_WIDGET = "RewardsEmptyStatePageWidget",
}

export enum REVIEWS_WIDGET_NAMES {
    REVIEWS_DRAWER_WIDGET = "ReviewsDrawerWidget",
    REVIEWS_PRODUCT_PAGE_WIDGET = "ReviewsProductPageWidget",
    PRODUCT_REVIEWS_MINI_SLIDER_WIDGET = "ProductReviewsMiniSliderWidget",
    REVIEWS_PAGE_WIDGET = "ReviewsPageWidget",
    LEAVE_REVIEW_PAGE_WIDGET = "LeaveReviewPageWidget",
    AVERAGE_REVIEWS_WIDGET = "AverageReviewsWidget",
    REVIEWS_SLIDER_WIDGET = "ReviewsSliderWidget",
    LISTING_AVERAGE_REVIEWS_WIDGET = "ListingAverageReviewsWidget",
    REVIEWS_FORM_MODAL_WIDGET = "ReviewsFormModalWidget",
    QUESTIONS_FORM_MODAL_WIDGET = "QuestionsFormModalWidget",
    REVIEWS_GALLERY_WIDGET = "ReviewsGalleryWidget",
    REVIEWS_GALLERY_MODAL_WIDGET = "ReviewsGalleryModalWidget",
    REVIEWS_BADGE_WIDGET = "ReviewsBadgeWidget",
}

export enum WISHLIST_WIDGET_NAMES {
    ADD_TO_WISHLIST_PRODUCT_CARD_WIDGET = "AddToWishlistProductCardWidget",
    ADD_TO_WISHLIST_BUTTON_WIDGET = "AddToWishlistButtonWidget",
    ADD_TO_WISHLIST_PRODUCT_PAGE_WIDGET = "AddToWishlistProductPageWidget",
    GO_TO_WISHLIST_PAGE_HEADER_WIDGET = "GoToWishlistPageHeaderWidget",
    WISHLIST_PAGE_WIDGET = "WishlistPageWidget",
    WISHLIST_DRAWER_WIDGET = "WishlistDrawerWidget",
    SHARED_WISHLIST_PAGE_WIDGET = "SharedWishlistPageWidget",
    SAVE_WISHLIST_MODAL_WIDGET = "SaveWishlistModalWidget",
}

export enum INSTAGRAM_WIDGET_NAMES {
    INSTAGRAM_GALLERY_WIDGET = "InstagramGalleryWidget",
}

export enum TIKTOK_WIDGET_NAMES {
    TIKTOK_GALLERY_WIDGET = "TikTokGalleryWidget",
}

export enum NUDGES_WIDGET_NAMES {
    NUDGES_WIDGET = "NudgesWidget",
}
