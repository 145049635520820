import { STATUS } from "@/constants/statuses";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { singleton } from "tsyringe";

import VoteEntity from "../entities/VoteEntity";

export type VotesState = EntityState<
    VoteEntity & { reviewId: number; status: STATUS },
    number
>;

function createInitialState(): VotesState {
    return {};
}

@singleton()
@StoreConfig({ name: "VotesStore", idKey: "reviewId" })
class VotesStore extends EntityStore<VotesState> {
    constructor() {
        super(createInitialState());
    }
}

export default VotesStore;
