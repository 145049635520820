import Minilog, { enable as minilogEnable } from "minilog";

import { LoggerInterface } from "@interfaces/LoggerInterface";

export class MinilogLogger implements LoggerInterface {
    minilog: Minilog;
    constructor(private readonly name: string) {
        this.minilog = Minilog(this.name);
        minilogEnable();
    }
    debug(message: string, additionalFields?: unknown): void {
        this.minilog.debug(...[message, additionalFields].filter((v) => !!v));
    }
    info(message: string, additionalFields?: unknown): void {
        this.minilog.info(...[message, additionalFields].filter((v) => !!v));
    }
    warn(message: string, additionalFields?: unknown): void {
        this.minilog.warn(...[message, additionalFields].filter((v) => !!v));
    }
    error(message: string, additionalFields?: unknown): void {
        this.minilog.error(...[message, additionalFields].filter((v) => !!v));
    }
    getName() {
        return this.name;
    }
    newLogger(name: string) {
        return new MinilogLogger([this.name, name].join("."));
    }
}

export default MinilogLogger;
