import { globalLoggerToken } from "@/tokens";
import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { authServiceApiClientToken } from "@modules/authentication/di_tokens";
import {
    TokensResponse,
    TokensResponseSchema,
} from "@modules/authentication/schemas/TokensResponseSchema";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { create } from "superstruct";
import { inject, singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

@singleton()
export class AuthApiService {
    public constructor(
        @inject(authServiceApiClientToken)
        private readonly apiClient: ApiClientInterface,
        @inject(globalLoggerToken)
        private readonly logger: LoggerInterface
    ) {}

    authWithProxy(params: {
        token: string | null;
        myshopifyDomain: string;
    }): Observable<TokensResponse> {
        return this.apiClient
            .post({
                url: "/auth/proxy",
                responseType: "json",
                contentType: "application/json",
                body: JSON.stringify({
                    token: params.token,
                }),
                queryParams: {
                    ...(params.token && { token: params.token }),
                    shop: params.myshopifyDomain,
                },
            })
            .pipe(
                map((value) => create(value.body, TokensResponseSchema)),
                map((result) => result)
            );
    }

    authWithSign(params: {
        shopifyCustomerId: number;
        signatureTimestamp: number;
        signature: string;
        token: string | null;
        myshopifyDomain: string;
    }): Observable<TokensResponse> {
        return this.apiClient
            .post({
                url: "/auth/signed",
                responseType: "json",
                contentType: "application/json",
                body: JSON.stringify(params),
                queryParams: {
                    ...(params.token && { token: params.token }),
                    shop: params.shopifyCustomerId,
                },
            })
            .pipe(
                map((value) => create(value.body, TokensResponseSchema)),
                map((result) => result)
            );
    }
}
