import {
    BatchRequestDocument,
    GraphQLClient,
    RequestDocument,
} from "graphql-request";
import { Observable, from } from "rxjs";
import { map } from "rxjs/operators";

export class GraphQLApiClient {
    private client: GraphQLClient;

    public constructor(graphqlApiUrl: string, token: string) {
        this.client = new GraphQLClient(graphqlApiUrl, {
            headers: {
                "X-Shopify-Storefront-Access-Token": token,
            },
        });
    }

    public request<T>(document: RequestDocument): Observable<T> {
        return from(this.client.request(document, {})).pipe(
            map((v) => {
                return v as T;
            })
        );
    }

    public batchRequest<T>(document: BatchRequestDocument[]): Observable<T> {
        return from(this.client.batchRequests(document, {})).pipe(
            map((v) => {
                return v as T;
            })
        );
    }
}
